import { getFunctions, httpsCallable } from "firebase/functions"; // Import the necessary functions
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db, app } from "../firebase"; // Ensure you have your Firebase app initialized

// Initialize Firebase Functions
const functions = getFunctions(app); // Initialize Firebase Functions with the app

export const getSubscriptionStatus = async (email) => {
  // Prepare the data to be sent to Firebase Function
  const rDict = {
    receiptData: "app",
    email: email,
    isWeb: true,
  };

  // Call the Firebase Function to validate the receipt
  try {
    const validateReceiptsIOS = httpsCallable(functions, "validateReceiptsIOS");
    const result = await validateReceiptsIOS({ dataDict: rDict });

    // Extract the data from the function result
    const retDict = result.data;

    // Extract subscription status and description
    const isSubscribed = retDict.data.isSubscribed;
    const description = retDict.data.description;

    console.log("**** description: " + description);

    return isSubscribed; // Return the subscription status
  } catch (error) {
    console.error("Error validating iOS receipt: ", error);
    return null;
  }
};

export const checkRecentAdminEmail = async (email) => {
  try {
    // Calculate timestamp for 30 days ago
    const thirtyDaysAgo = Math.floor(Date.now() / 1000) - 30 * 24 * 60 * 60;

    // Create query to find documents where:
    // 1. email matches the provided email
    // 2. timestamp is greater than 30 days ago
    const q = query(
      collection(db, "adminXlsEmail"),
      where("email", "==", email),
      where("timestamp", ">=", thirtyDaysAgo)
    );

    // Execute the query
    const querySnapshot = await getDocs(q);

    // If there are matching documents, return the first document's data
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0]; // Get the first matching document
      return {
        id: doc.id,
        ...doc.data(), // Spread the document data
      };
    }

    // If no matching documents found, return null
    return null;
  } catch (error) {
    console.error("Error checking adminXlsEmail collection: ", error);
    return null; // Return null in case of error
  }
};

export const removeAdminXlsEmail = async (email) => {
  try {
    console.log("cancel email: " + email);
    // Create a callable function reference to removeAdminXlsEmail
    const removeFunction = httpsCallable(functions, "removeAdminXlsEmail");

    // Call the Cloud Function with the email parameter
    const result = await removeFunction({ email });

    // Return the result data from the Cloud Function
    return result.data; // { success, message, deletedCount }
  } catch (error) {
    console.error("Error calling removeAdminXlsEmail:", error);
    throw error; // Re-throw the error for the caller to handle
  }
};
